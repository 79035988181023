export function setToken(token: string): void {
  sessionStorage.setItem('token', token);
}

export function setRefreshToken(token: string): void {
  sessionStorage.setItem('refreshToken', token);
}

export function setUserId(id: string) {
  sessionStorage.setItem('userId', id);
}

export function getToken() {
  return sessionStorage.getItem('token');
}

export function setHasExtraPermission(hasExtraPermission: boolean) {
  sessionStorage.setItem('hasExtraPermission', `${hasExtraPermission}`);
}

export function getHasExtraPermission() {
  const hasExtraPermission = sessionStorage.getItem('hasExtraPermission');
  return hasExtraPermission === 'true';
}




export function getRefreshToken() {
  return sessionStorage.getItem('refreshToken');
}

export function getUserId() {
  return sessionStorage.getItem('userId');
}

export function getUser() {
  return sessionStorage.getItem('user');
}

export function removeToken() {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('refreshToken');
}
